import React, {useContext, useState} from 'react';
import {Context} from "../index";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import '../assets/scss/pages/registration-form.scss'
import '../assets/scss/pages/login-form.scss'
import '../assets/scss/pages/pin-authorization.scss'
import imgLabel from '../assets/images/snow.svg'
import {INDEX_ROUTE} from "../utils/consts";
import AuthUserService from "../services/AuthUserService";
import FormButtonSubmit from "../components/user/FormButtonSubmit";

const UserPINAuthorization = () => {
    const {userStore} = useContext(Context)
    const history = useNavigate()
    const user = userStore.user
    const pinRows = user.pins.rows
    const [pinId, setPinId] = useState(user.pins.rows[0].id)

    const pinAuthorization = async () => {
        try {
            await AuthUserService.bindDeviceToPin(pinId, navigator.userAgent).then((response) => {
                console.log('pin login true = ', response);
                if (response?.error) {
                    console.log(response?.error);
                    // history(INDEX_ROUTE)
                }else{
                    history(INDEX_ROUTE)
                }
            })
        } catch (e) {
            console.log('pin login false = ', e.response?.data);
            return {error: true, message: e.response?.data?.message}
        }
    }

    return (
        <div className="registration-form login-form pin-authorization">
            <h2 className={'title-label'}>
                Sign In
                <img src={imgLabel} alt="snow"/>
            </h2>
            <span>It looks like you have multiple ornaments. Select an ornament to associate this device with.</span>
            <div className={'form-info'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                        fill="#006548"/>
                </svg>
                Each ornament is limited to 5 devices.
            </div>
            <form action="" method="">
                <h5>Select an ornament:</h5>
                <div className="input-radio">
                    {pinRows && pinRows.map((item, index) =>
                        <label key={item.id}>
                            <input
                                onChange={e => setPinId(e.target.value)}
                                value={item.id}
                                name="pin"
                                type="radio"
                                checked={Number(pinId) === item.id}
                            />
                            <span className="icon-radio"></span>
                            <div className="input-radio-text">
                                <span className="pin-name">
                                    {item.name ? `${item.name} (${item.pin})` : item.pin}
                                </span>
                                <span className='device-count'>
                                    {item.count_devices} device connected
                                </span>
                            </div>

                        </label>
                    )}
                </div>
                <FormButtonSubmit name={'Sign In'} color={'red'} callback={pinAuthorization}/>
            </form>
        </div>
    );
};

export default observer(UserPINAuthorization);

