import $api from "../http";

export default class AdminService{
    static fetchAdmins(page, limit, fieldName, direction, query, field){
        return $api.get('api/admin/admins',{params:{
                page, limit, fieldName, direction, query, field
            }})
    }
    static fetchPins(page, limit, fieldName, direction, query, field){
        return $api.get('api/admin/table/pins',{params:{
                page, limit, fieldName, direction, query, field
            }})
    }

    static fetchOneAdmin(id){
        return $api.get('api/admin/'+id)
    }

}
