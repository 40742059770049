import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import LayoutAdmin from "../components/admin/LayoutAdmin";
import AdminService from "../services/AdminService";
import {useNavigate, useParams} from "react-router-dom";
import AuthService from "../services/AuthService";
import {ADMIN_LOGIN_ROUTE, ADMIN_TABLE_ROUTE} from "../utils/consts";

const AdminProfile = () => {
    const {adminStore} = useContext(Context)
    const admin = adminStore.admin
    const {id} = useParams()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [role, setRole] = useState('')
    const history = useNavigate()
    const isAdmin = admin.isActivated && admin.role === 'ADMIN'
    console.log('Admin', admin);

    useEffect(()=>{
        AdminService.fetchOneAdmin(id).then((response)=>{
            console.log('fetchOneAdmin', response.data);
            setEmail(response.data.email)
            setFirstName(response.data.first_name)
            setLastName(response.data.last_name)
            setRole(response.data.role)
        })
    },[])

    const update = () => {
      AuthService.update(id, email, password, firstName, lastName, role)
        console.log(id, email, password, firstName, lastName, role)
    }
    const remove = () => {
        AuthService.delete(id).then((data)=>{
            if (admin.id === Number(id) && data){
                adminStore.logout().then(()=>{
                    history(ADMIN_LOGIN_ROUTE)
                })
            }else if (data){
                history(ADMIN_TABLE_ROUTE)
            }
        })
    }
    return (
        <LayoutAdmin>
            <div className="profile">
                <div className="profile-header">
                    <div className="profile-header-cover"></div>
                    <div className="profile-header-content">
                        <div className="profile-header-info">
                            <h4 className="mt-0 mb-1">{firstName} {lastName}</h4>
                            <p className="mb-2">{email}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-content">
                <div className="tab-content p-0">
                    <div className="tab-pane fade active show" id="profile-about">
                        <div className="table-responsive form-inline">
                            <table className="table table-profile align-middle">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>
                                            <h4>{firstName} {lastName}
                                                <small>{email}</small>
                                            </h4>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr className="highlight">
                                    <td className="field">Firstname</td>
                                    <td>
                                        <input
                                            onChange={e => setFirstName(e.target.value)}
                                            value={firstName}
                                            type="text"
                                            className="form-control fs-13px"
                                            placeholder="Firstname"
                                        />
                                    </td>
                                </tr>
                                <tr className="highlight">
                                    <td className="field">Lastname</td>
                                    <td>
                                        <input
                                            onChange={e => setLastName(e.target.value)}
                                            value={lastName}
                                            type="text"
                                            className="form-control fs-13px"
                                            placeholder="Lastname"
                                        />
                                    </td>
                                </tr>
                                <tr className="divider">
                                    <td colSpan="2"></td>
                                </tr>
                                <tr>
                                    <td className="field">Email</td>
                                    <td>
                                        <input
                                            onChange={e => setEmail(e.target.value)}
                                            value={email}
                                            type="text"
                                            className="form-control fs-13px"
                                            placeholder="Email address"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="field">Password</td>
                                    <td>
                                        <input
                                            onChange={e => setPassword(e.target.value)}
                                            value={password}
                                            type="password"
                                            className="form-control fs-13px"
                                            placeholder="Password"
                                        />
                                    </td>
                                </tr>
                                {isAdmin &&
                                    <tr>
                                        <td className="field">Role</td>
                                        <td>
                                            <select
                                                onChange={e => setRole(e.target.value)}
                                                className="form-select w-250px"
                                                name="role"
                                                defaultValue={role}
                                            >
                                                <option value="ADMIN">Admin</option>
                                                <option value="MEMBER">Member</option>
                                            </select>
                                        </td>
                                    </tr>
                                }
                                <tr className="highlight">
                                    <td className="field">&nbsp;</td>
                                    <td className="">
                                        <button type="button"
                                                onClick={update}
                                                className="btn d-flex btn-primary w-150px">
                                            Update
                                        </button>
                                        <button type="button"
                                                onClick={remove}
                                                className="btn d-flex btn-primary w-150px  ms-5px">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutAdmin>
    )
};

export default observer(AdminProfile);
