import React, {memo} from 'react';

const TableTh = ({sortTable, sortParams, name}) => {
    return (
        <th
            onClick={(event) => sortTable(sortParams.fieldName, sortParams.direction, event)}
            className={`sorting`}
        >
            {name}
        </th>
    );
};

export default memo(TableTh);
