import React, {useEffect, useState} from 'react';
import {useDebounce} from '../../utils/useDebounce'
import {ADMIN_REGISTRATION_ROUTE} from "../../utils/consts";
import {Link} from "react-router-dom";

const SearchForm = ({getQuerySearch, getLimitItems, isButton, children}) => {
    const [querySearch, setQuerySearch] = useState('')
    const [fieldSearch, setFieldSearch] = useState('email')
    const debouncedSearchTerm = useDebounce(querySearch, 500);
    // console.log('sf== ',querySearch);
    useEffect(() => {
        if (debouncedSearchTerm || querySearch === '') {
            getQuerySearch(debouncedSearchTerm, fieldSearch)
        }
    }, [debouncedSearchTerm, fieldSearch])
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    return (
        <div className="row">
            <div className="col-sm-12 col-md-6">
                <div className="dataTables_length"
                     id="data-table-default_length">
                    <label>Show
                        <select
                            name="data-table-default_length"
                            aria-controls="data-table-default"
                            className="form-select form-select-sm"
                            onChange={(e) => getLimitItems(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select> entries</label>
                    {isButton &&
                        <Link to={ADMIN_REGISTRATION_ROUTE} className="btn btn-success ms-3 mb-1">Add New Admin</Link>
                    }
                </div>
            </div>
            <div className="d-flex gap-3 justify-content-end col-sm-12 col-md-6">
                <div className="dataTables_length" id="data-table-default_length">
                    <label>Fields:
                        <select
                            name="data-table-default_length"
                            aria-controls="data-table-default"
                            className="form-select form-select-sm"
                            onChange={(e) => setFieldSearch(e.target.value)}
                        >
                            {children}
                        </select>
                    </label>
                </div>
                <div id="data-table-default_filter" className="dataTables_filter">
                    <label>
                        Search:
                        <input
                            value={querySearch}
                            onKeyDown={(e) => handleKeyPress(e)}
                            onChange={(e) => setQuerySearch(e.target.value)}
                            type="search"
                            className="form-control form-control-sm"
                            placeholder=""
                            aria-controls="data-table-default"
                        />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default SearchForm;
