import React, {useContext} from 'react';
import {Context} from "../../index";

const ButtonCollapse = () => {
    const {adminStore} = useContext(Context)
    const buttonCollapse = () => {
        adminStore.setSidebarCollapsed(!adminStore.isSidebarCollapsed)
    }
    return (
        <div className="menu-item d-flex">
            <a className="app-sidebar-minify-btn ms-auto"
               onClick={buttonCollapse}
               data-toggle="app-sidebar-minify"
            >
                <i className="fa fa-angle-double-left"></i>
            </a>
        </div>
    );
};

export default ButtonCollapse;
