import React, {useContext, useRef, useState} from 'react';
import {Context} from "../../index";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import FormButtonSubmit from "./FormButtonSubmit";
import '../../assets/scss/pages/registration-form.scss'
import ModalDialogBoxContent from "./ModalDialogBoxContent";
import imgLabel from '../../assets/images/snow.svg'
import {USER_VERIFY_MESSAGE_ROUTE} from "../../utils/consts";


const RegistrationForm = () => {
    const {userStore} = useContext(Context)
    const checkTerm = useRef()
    const history = useNavigate()
    const [email, setEmail] = useState('')
    const [terms, setTerms] = useState(false)
    const [marketEmail, setMarketEmail] = useState(false)
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [errors, setErrors] = useState({})
    const passRef = useRef(null)
    const passInput = passRef.current
    const emailRef = useRef(null)
    const emailInput = emailRef.current
    console.log('RegistrationForm');

    const signUp = async () => {
        const err = {}

        if (!email) {
            err.email = 'Required'
            emailInput.classList.add('is-invalid')
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            err.email = 'Invalid email address'
            emailInput.classList.add('is-invalid')
        } else {
            err.email = 'valid'
            emailInput.classList.remove('is-invalid')
        }

        if (!terms){
            checkTerm.current.classList.add('is-invalid')
        }else{
            checkTerm.current.classList.remove('is-invalid')
        }

        if (!password && !passInput.value) {
            err.pass = 'Required'
            passInput.classList.remove('is-valid')
            passInput.classList.add('is-invalid')
        } else if (password !== passInput.value) {
            err.pass = 'Password mismatch'
            passInput.classList.remove('is-valid')
            passInput.classList.add('is-invalid')
        } else if (passInput.value.length < 8) {
            err.pass = 'Must be more than 8 characters'
            passInput.classList.remove('is-valid')
            passInput.classList.add('is-invalid')
        } else {
            err.pass = 'valid'
            passInput.classList.add('is-valid')
            passInput.classList.remove('is-invalid')
        }

        setErrors(err)
        console.log(err, terms);
        try {
            if (err.pass === 'valid' && err.email === 'valid' && terms) {
                console.log('send');
                await userStore.registration(email, password, marketEmail).then((response) => {
                    console.log('login true = ', response);
                    if (response?.error) {
                        emailInput.classList.add('is-invalid')
                        setErrors({email:response.message})
                    } else {
                        emailInput.classList.remove('is-valid')
                        history(USER_VERIFY_MESSAGE_ROUTE)
                    }
                })
                emailInput.classList.remove('is-valid')
            }

        } catch (e) {
            console.log('login false = ', e.response?.data);
        }
    }
    return (
        <div className="registration-form">
            <h2 className={'title-label'}>
                Create Account
                <img src={imgLabel} alt="snow"/>
            </h2>
            <span>Sign up with your email address</span>
            <form action="" method="">
                <div className="input-text">
                    <input
                        ref={emailRef}
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        type="email"
                        className=""
                        id="emailAddress"
                        placeholder="example@gmail.com"
                        required
                    />
                    <div className="invalid-feedback">{errors.email}</div>
                    <label
                        htmlFor="emailAddress"
                        className=""
                    >
                        Email
                    </label>
                </div>

                <div className="input-text">
                    <input
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type="password"
                        className=""
                        id="password"
                        placeholder="Create a password"
                        required
                    />
                    <label
                        htmlFor="password"
                        className=""
                    >
                        Password
                    </label>
                </div>
                <div className="input-text">
                    <input
                        ref={passRef}
                        type="password"
                        className=""
                        id="re-password"
                        placeholder="Confirm Password"
                    />
                    <div className="invalid-feedback">{errors.pass}</div>
                    <label
                        htmlFor="re-password"
                        className=""
                    >
                        Confirm Password
                    </label>
                </div>


                <div className="input-checkbox">
                    <label htmlFor="term">
                        <input
                            ref={checkTerm}
                            type="checkbox"
                            onChange={() => setTerms(!terms)}
                            id={'term'}
                        />
                        <span>
                        I am at least 18 years old and I agree to the <a href="#" data-bs-toggle="modal"
                                                                         data-bs-target={"#modalTerm"}>Terms & Conditions</a>
                        </span>
                    </label>

                    <label htmlFor="market_email">
                        <input
                            onChange={() => setMarketEmail(!marketEmail)}
                            type="checkbox"
                            id={'market_email'}/>
                        <span>
                        I would like to receive marketing emails
                        </span>
                    </label>
                </div>

                <div className="policy">
                    <a href="">Privacy policy</a>
                </div>

                <FormButtonSubmit name={'Next'} color={'red'} callback={signUp}/>

            </form>
            <ModalDialogBoxContent
                title={'Terms & Conditions'}
                modalId={'modalTerm'}
            >
                <div className={'modal-support'}>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </div>
            </ModalDialogBoxContent>
        </div>
    );
};

export default observer(RegistrationForm);
