import React, {useContext, useRef, useState} from 'react';
import {Context} from "../index";
import {Link, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import '../assets/scss/pages/registration-form.scss'
import '../assets/scss/pages/login-form.scss'
import imgLabel from '../assets/images/snow.svg'
import {REGISTRATION_ROUTE, USER_PIN_AUTHORIZATION_ROUTE} from "../utils/consts";
import FormButtonSubmit from "../components/user/FormButtonSubmit";
import UserStore from "../store/UserStore";


const UserLoginForm = () => {
    const {userStore} = useContext(Context)
    const history = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})
    const passRef = useRef(null)
    const passInput = passRef.current
    const emailRef = useRef(null)
    const emailInput = emailRef.current
    console.log('RegistrationForm');
    const user = userStore.user

    const signIn = async () => {
        console.log('user', user.email);
        const err = {}

        if (!email) {
            err.email = 'Required'
            emailInput.classList.add('is-invalid')
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            err.email = 'Invalid email address'
            emailInput.classList.add('is-invalid')
        } else {
            err.email = 'valid'
            emailInput.classList.remove('is-invalid')
        }

        if (!password && !passInput.value) {
            err.pass = 'Required'
            passInput.classList.remove('is-valid')
            passInput.classList.add('is-invalid')
        } else if (passInput.value.length < 8) {
            err.pass = 'Must be more than 8 characters'
            passInput.classList.remove('is-valid')
            passInput.classList.add('is-invalid')
        } else {
            err.pass = 'valid'
            passInput.classList.add('is-valid')
            passInput.classList.remove('is-invalid')
        }

        setErrors(err)
        console.log(err);

        try {
            if (err.pass === 'valid' && err.email === 'valid') {
                console.log('send');
                await userStore.login(email, password).then((response) => {
                    console.log('login true = ', response);
                    if (response?.error) {
                        emailInput.classList.add('is-invalid')
                        setErrors({email:response.message})
                    } else {
                        emailInput.classList.remove('is-invalid')
                        history(USER_PIN_AUTHORIZATION_ROUTE)
                    }
                })
                emailInput.classList.remove('is-valid')
            }

        } catch (e) {
            console.log('login false = ', e.response?.data);
            return { error: true, message: e.response?.data?.message}
        }
    }

    return (
        <div className="registration-form login-form">
            <h2 className={'title-label'}>
                Sign In
                <img src={imgLabel} alt="snow"/>
            </h2>
            <span>Sign in with your email address</span>
            <form action="" method="">
                <div className="input-text">
                    <input
                        ref={emailRef}
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        type="email"
                        className=""
                        id="emailAddress"
                        placeholder="example@gmail.com"
                        required
                    />
                    <div className="invalid-feedback">{errors.email}</div>
                    <label
                        htmlFor="emailAddress"
                        className=""
                    >
                        Email
                    </label>
                </div>

                <div className="input-text">
                    <input
                        ref={passRef}
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type="password"
                        className=""
                        id="password"
                        placeholder="Create a password"
                        required
                    />
                    <div className="invalid-feedback">{errors.pass}</div>
                    <label
                        htmlFor="password"
                        className=""
                    >
                        Password
                    </label>
                </div>

                <div className="link-info">
                    <Link to={''}>Forgot password?</Link>
                </div>

                <FormButtonSubmit name={'Sign In'} color={'red'} callback={signIn}/>

            </form>
            <div className="bottom-info">
                Don’t have an account?
                <Link to={REGISTRATION_ROUTE}>
                     Sign Up
                </Link>
            </div>
            <div className="policy">
                <a href="">Privacy policy</a>
            </div>

        </div>
    );
};

export default observer(UserLoginForm);

