import React, {useContext} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {Context} from "../index";
import '../assets/css/default/app.min.css'
import '../assets/css/vendor.min.css'
import '../assets/css/default/fonts/bootstrap-icons.woff'
import '../assets/css/default/fonts/bootstrap-icons.woff2'
import {
    USER_ACTIVATE_PIN_ROUTE,
    INDEX_ROUTE,
    LOGIN_ROUTE,
    REGISTRATION_ROUTE, USER_ROUTE,
    USER_VERIFY_ROUTE, USER_VERIFY_MESSAGE_ROUTE, USER_ACCOUNT_CREATED_ROUTE, USER_PIN_AUTHORIZATION_ROUTE
} from "../utils/consts";
import {observer} from "mobx-react-lite";
import PrivateRoute from "./user/PrivateRoute";
import RegistrationForm from "./user/RegistrationForm";
import Index from "../pages/Index";
import UserVerify from "../pages/UserVerify";
import UserActivatePin from "../pages/UserActivatePin";
import UserLoginForm from "../pages/UserLoginForm";
import UserVerifyMessage from "../pages/UserVerifyMessage";
import UserAccountCreated from "../pages/UserAccountCreated";
import UserPINAuthorization from "../pages/UserPINAuthorization";

const UserRouter = () => {
    const {userStore} = useContext(Context)
    if (localStorage.getItem('tokenUser')) {
        userStore.checkAuth()
    }
    console.log('UserRouter', userStore.isAuth, userStore.isLoading,userStore.user);

    return (
        //switch
        <Routes>
            <Route path={USER_ROUTE} element={<PrivateRoute/>}>
                <Route path={USER_VERIFY_ROUTE} element={<UserVerify/>}/>
                <Route path={USER_VERIFY_MESSAGE_ROUTE} element={<UserVerifyMessage/>}/>
                <Route path={USER_ACTIVATE_PIN_ROUTE} element={<UserActivatePin/>}/>
                <Route path={USER_ACCOUNT_CREATED_ROUTE} element={<UserAccountCreated/>}/>
                <Route path={USER_PIN_AUTHORIZATION_ROUTE} element={<UserPINAuthorization/>}/>
            </Route>
            <Route path={INDEX_ROUTE} element={<Index/>}/>
            <Route path={REGISTRATION_ROUTE} element={<RegistrationForm/>}/>
            <Route path={LOGIN_ROUTE} element={<UserLoginForm/>}/>
            <Route path="user/*" element={<Navigate to={LOGIN_ROUTE} replace/>}/>
        </Routes>

    );
};

export default UserRouter;
