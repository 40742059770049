import React from 'react';
import {Link} from "react-router-dom";
import '../../assets/scss/components/form-button.scss'

const FormButtonSubmit = ({name, color, callback}) => {
    return (
        <button type="button" className={`btn btn-${color}`} onClick={()=>callback()}>{name}</button>
    );
};

export default FormButtonSubmit;
