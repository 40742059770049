import React from 'react';
import {Link} from "react-router-dom";
import '../../assets/scss/components/layout-user-page.scss'
import imgBanner from '../../assets/images/banner.jpg'
import imgLogo from '../../assets/images/logo.png'

const LayoutUserPage = ({children}) => {
    console.log(imgBanner);
    return (
        <div>
            <div className="banner">
                <img src={imgBanner} className={'banner-img'} alt="Santa's Kindness"/>

                <div className="logo">
                    <Link to={'/'}>
                        <img src={imgLogo} alt="Santa's Kindness"/>
                    </Link>
                </div>
            </div>
            <div className="container-form">
                {children}
            </div>
        </div>
    );
};

export default LayoutUserPage;
