import React, {useContext, useRef, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const RegistrationForm = () => {
    const [email, setEmail] = useState('')
    const [firsName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [messageActivate, setMessageActivate] = useState(false)
    const [errors, setErrors] = useState({})
    const [errorMessage, setErrorMessage] = useState('Create new Admin')
    const {adminStore} = useContext(Context)
    const emailRef = useRef(null)
    const emailInput = emailRef.current


    const validationForm = async () => {
        const err = {}
        if (!email) {
            err.email = 'Required'
            emailInput.classList.add('is-invalid')
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            err.email = 'Invalid email address'
            emailInput.classList.add('is-invalid')
        } else {
            err.email = 'valid'
            emailInput.classList.remove('is-invalid')
        }

        setErrors(err)
        if (err.email === 'valid') {
            setErrors({})
            await adminStore.registration(email, firsName, lastName).then((response) => {
                if (response?.error) {
                    emailInput.classList.add('is-invalid')
                    setErrorMessage(response.message)
                } else {
                    emailInput.classList.remove('is-invalid')
                    setEmail('')
                    setFirstName('')
                    setLastName('')
                    setMessageActivate(true)
                    setErrorMessage('Create new Admin')
                    setTimeout(() => setMessageActivate(false), 5000);
                }

            })

        }
    }

    return (
        <div className='register register-with-news-feed'>
            <div className="register-container">
                <div className="register-header mb-25px h1">
                    {/*<div className="mb-1">Sign Up</div>*/}
                    {messageActivate ?
                        <small className="d-block fs-15px lh-16 link-green">Account successfully created.</small>
                        :
                        <small className="d-block fs-15px lh-16">{errorMessage}</small>
                    }
                </div>

                <div className="register-content">
                    <form action="" method="" className="fs-13px" data-parsley-validate="true">
                        <div className="mb-3">
                            <label className="mb-2">Email <span className="text-danger">*</span></label>
                            <input
                                ref={emailRef}
                                onChange={e => setEmail(e.target.value)}
                                data-parsley-type="email"
                                value={email}
                                type="email"
                                className="form-control fs-13px"
                                placeholder="Email"
                            />
                            <div className="valid-feedback">Entered correctly</div>
                            <div className="invalid-feedback">{errors.email}</div>
                        </div>
                        <div className="mb-3">
                            <label className="mb-2">Name</label>
                            <div className="row gx-3">
                                <div className="col-md-6 mb-2 mb-md-0">
                                    <input
                                        onChange={e => setFirstName(e.target.value)}
                                        value={firsName}
                                        type="text"
                                        className="form-control fs-13px"
                                        placeholder="First name"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <input
                                        onChange={e => setLastName(e.target.value)}
                                        value={lastName}
                                        type="text"
                                        className="form-control fs-13px"
                                        placeholder="Last name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <button
                                onClick={validationForm}
                                type="button"
                                className="btn btn-primary w-100 btn-lg h-45px fs-13px"
                            >
                                Register
                            </button>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    );
};

export default observer(RegistrationForm);
