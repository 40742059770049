import React, {useRef, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {ADMIN_LOGIN_ROUTE} from "../utils/consts";
import AuthService from "../services/AuthService";

const ResetPassword = () => {
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})
    const history = useNavigate()
    const {id, token} = useParams()
    console.log(id, token);
    const passRef = useRef(null)
    const passInput = passRef.current
    const sendPassword = async () => {
        const err = {}
        if (!password && !passInput.value) {
            err.pass = 'Required'
            passInput.classList.remove('is-valid')
            passInput.classList.add('is-invalid')
        } else if (password !== passInput.value) {
            err.pass = 'Password mismatch'
            passInput.classList.remove('is-valid')
            passInput.classList.add('is-invalid')
        } else if (passInput.value.length < 8) {
            err.pass = 'Must be more than 8 characters'
            passInput.classList.remove('is-valid')
            passInput.classList.add('is-invalid')
        } else {
            err.pass = 'valid'
            passInput.classList.add('is-valid')
            passInput.classList.remove('is-invalid')
        }

        setErrors(err)
        console.log(err);
        if (err.pass === 'valid') {
            await AuthService.resetPassword(id, token, password).then((data)=>{
                console.log(data);
                if (data?.status === 200){
                    history(ADMIN_LOGIN_ROUTE)
                }
            })
        }
    }

    return (
        <div className="login login-v1 reset-password">
            <div className="login-container">
                <div className="login-header">
                    <div className="brand">
                        <div className="d-flex align-items-center">
                            Reset Password
                        </div>
                    </div>
                    <div className="icon">
                        <i className="fa fa-lock"></i>
                    </div>
                </div>
                <div className="login-body">
                    <div className="login-content fs-13px">
                        <form action="" method="">
                            <div className="form-floating mb-20px">
                                <input
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    type="password"
                                    className="form-control fs-13px h-45px"
                                    id="password"
                                    placeholder="Password"
                                />
                                <label
                                    htmlFor="password"
                                    className="d-flex align-items-center py-0"
                                >
                                    Password
                                </label>
                            </div>
                            <div className="form-floating mb-20px">
                                <input
                                    ref={passRef}
                                    type="password"
                                    className="form-control fs-13px h-45px"
                                    id="re-password"
                                    placeholder="Re-enter Password"
                                />
                                <div className="valid-feedback">Entered correctly</div>
                                <div className="invalid-feedback">{errors.pass}</div>
                                <label
                                    htmlFor="re-password"
                                    className="d-flex align-items-center py-0"
                                >
                                    Re-enter Password
                                </label>
                            </div>

                            <div className="login-buttons mb-20px">
                                <button
                                    type="button"
                                    onClick={sendPassword}
                                    className="btn h-45px btn-success  d-flex w-100 btn-lg"
                                >
                                    Save Password
                                </button>
                            </div>
                            <div className="text-gray-500"> Click&nbsp;
                                <Link to={ADMIN_LOGIN_ROUTE} className="text-white">
                                    here
                                </Link>
                                &nbsp;to login.
                            </div>

                        </form>
                    </div>

                </div>

            </div>

        </div>

    )
};

export default ResetPassword;
