import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import '../assets/scss/pages/registration-form.scss'
import imgLabel from '../assets/images/snow.svg'
import '../assets/scss/pages/user-verify.scss'
import {Context} from "../index";
import FormButton from "../components/user/FormButton";
import {USER_ACTIVATE_PIN_ROUTE} from "../utils/consts";


const UserVerify = () => {

    const {userStore} = useContext(Context)
    console.log('UserVerify', userStore.user.email);

    return (
        <div className="registration-form user-verify">
            <h2 className={'title-label'}>
                Verify Email Address
                <img src={imgLabel} alt="snow"/>
            </h2>
            <span>
                Your email has been verified <a href={`mailto:${userStore.user.email}`}>{userStore.user.email}</a>.
            </span><br/>
            <span>Go to the next step.</span>
            <div className={'block-button'}>
                <FormButton path={USER_ACTIVATE_PIN_ROUTE} color={'red'} name={'Next'}/>
            </div>

        </div>
    );
};

export default observer(UserVerify);
