import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";
import axios from "axios";
import {API_URL} from "../http";

export default class AdminStore {
    admin = {}
    isAuth = false
    isLoading = false
    isSidebarCollapsed = false
    constructor() {
        makeAutoObservable(this)
    }

    setAuth(bool){
        this.isAuth = bool
    }
    setSidebarCollapsed(bool){
        this.isSidebarCollapsed = bool
    }

    setAdmin(admin){
        this.admin = admin
    }

    setLoading(boll){
        this.isLoading = boll
    }

    async login(email, password){
        try {
            const response = await AuthService.login(email, password)
            console.log('login',response);
            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setAdmin(response.data.admin)
        } catch (e) {
            console.log(e.response?.data?.message)
            return { error: true, message: e.response?.data?.message}
        }
    }
    async registration(email, firstName, lastName){
        try {
            console.log('reg',email, firstName, lastName);
            const response = await AuthService.registration(email, firstName, lastName)
            console.log('reg', response);
            // localStorage.setItem('token', response.data.accessToken)
            // this.setAuth(false)
            // this.setAdmin(response.data.admin)
        } catch (e) {
            console.log(e.response?.data?.message)
            return { error: true, message: e.response?.data?.message}
        }
    }

    async logout(){
        try {
            await AuthService.logout()
            localStorage.removeItem('token')
            this.setAuth(false)
            this.setAdmin({})
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async checkAuth(){
        this.setLoading(true)
        try {
            const response = await axios.get(`${API_URL}api/admin/refresh`, {withCredentials: true})
            // console.log('checkAuth', response);
            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setAdmin(response.data.admin)
        } catch (e) {
            console.log(e.response?.data?.message)
        } finally {
            console.log('setLoading');
            this.setLoading(false)
        }
    }
}
