import React, {useEffect, useState} from 'react';
import AdminService from "../services/AdminService";
import '../assets/css/admin.css'
import $ from 'jquery'
import PaginationPage from "../components/PaginationPage";
import TableTh from "../components/admin/TableTh";
import TableInfo from "../components/admin/TableInfo";
import SearchForm from "../components/admin/SearchForm";
import LayoutTable from "../components/admin/LayoutTable";

const Table = () => {
    const [users, setUsers] = useState([])
    const [countItems, setCountItems] = useState(0)
    const [numberPage, setNumberPage] = useState(1)
    const [sortDirection, setSortDirection] = useState(true)
    const [nameDirection, setNameDirection] = useState('ASC')
    const [sortFieldName, setSortFieldName] = useState('id')
    const [term, setTerm] = useState()
    const [field, setField] = useState()
    const [watch, setWatch] = useState(false)
    const [limitItems, setLimitItems] = useState(10)
    const lastPage = limitItems * numberPage
    const endItemsCurrentPage = lastPage <= countItems ? lastPage : countItems
    const startItemsCurrentPage = lastPage <= countItems ? endItemsCurrentPage - limitItems + 1 : lastPage - limitItems + 1
    const isPaginate = limitItems < countItems
    const paginationConfig = {
        totalCount: countItems,
        limit: limitItems,
        page: numberPage
    }

    useEffect(() => {
        AdminService.fetchAdmins(numberPage, limitItems, sortFieldName, nameDirection, term, field)
            .then(response => {
                console.log('table ===', response);
                setUsers(response.data.rows)
                setCountItems(response.data.count)
            })
    }, [numberPage, term, limitItems])

    const changeCurrentPage = (page) => {
        setNumberPage(page)
    }

    const getQuerySearch = (query, field) => {
        if (watch){
            setTerm(query)
            setField(field)
            setNumberPage(1)
        }
        setWatch(true)
    }
    const getLimitItems = (count) => {
        if (watch){
            setLimitItems(count)
        }
        setWatch(true)
    }
    const sortTable = (fieldName, direct, event) => {
        if (countItems > 1) {

            if (fieldName === sortFieldName) {
                direct = !direct
                setSortDirection(direct)
            } else {
                setSortDirection(true)
                direct = true
                setSortFieldName(fieldName)
            }

            const direction = direct ? 'ASC' : 'DESC'
            setNameDirection(direction)
            const selectorSorting = $(document).find('.sorting')

            selectorSorting.removeClass('sorting_asc')
            selectorSorting.removeClass('sorting_desc')

            console.log(event.target, fieldName, sortFieldName, direction, sortDirection, direct);

            if (direct) {
                event.target.classList.add('sorting_asc')
                event.target.classList.remove('sorting_desc')
            } else {
                event.target.classList.add('sorting_desc')
                event.target.classList.remove('sorting_asc')
            }

            AdminService.fetchAdmins(numberPage, limitItems, fieldName, direction, term, field)
                .then(response => {
                    setUsers(response.data.rows)
                    setCountItems(response.data.count)
                })
        }
    }

    return (
        <LayoutTable panelTitle={'Admins Table'}>
            <SearchForm getQuerySearch={getQuerySearch} getLimitItems={getLimitItems} isButton={true}>
                <option value="email">Email</option>
                <option value="first_name">First Name</option>
                <option value="last_name">Last Name</option>
            </SearchForm>
            <table className="table dataTable table-hover table-bordered align-middle table-striped mb-0 text-dark">
                <thead>
                <tr>
                    <TableTh
                        sortTable={sortTable}
                        sortParams={{fieldName: 'id', direction: sortDirection}}
                        name='#'
                    />
                    <TableTh
                        sortTable={sortTable}
                        sortParams={{fieldName: 'email', direction: sortDirection}}
                        name='Email'
                    />
                    <TableTh
                        sortTable={sortTable}
                        sortParams={{fieldName: 'first_name', direction: sortDirection}}
                        name='First Name'
                    />
                    <TableTh
                        sortTable={sortTable}
                        sortParams={{fieldName: 'last_name', direction: sortDirection}}
                        name='Last Name'
                    />
                    <TableTh
                        sortTable={sortTable}
                        sortParams={{fieldName: 'isActivated', direction: sortDirection}}
                        name='Activated'
                    />
                </tr>
                </thead>
                <tbody>
                {users &&
                    users.map(user =>
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.email}</td>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                            <td>{user.isActivated ? 'true' : 'false'}</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            <div className="row">
                <div className="col-sm-12 col-md-5">
                    <TableInfo
                        startItemsCurrentPage={startItemsCurrentPage}
                        endItemsCurrentPage={endItemsCurrentPage}
                        countItems={countItems}
                    />
                </div>
                <div className="col-sm-12 col-md-7">
                    {
                        isPaginate &&
                        <PaginationPage items={paginationConfig} changeCurrentPage={changeCurrentPage}/>
                    }
                </div>
            </div>
        </LayoutTable>
    );
};

export default Table;
