import React from 'react';
import {LOGIN_ROUTE, REGISTRATION_ROUTE} from "../utils/consts";
import '../assets/scss/pages/index.scss'
import LayoutUserPage from "../components/user/LayoutUserPage";
import FormButton from "../components/user/FormButton";
import FormSplitter from "../components/user/FormSplitter";
import ModalDialogBoxContent from "../components/user/ModalDialogBoxContent";

const Index = () => {
    return (
        <LayoutUserPage>
            <h4>
                To receive messages from Santa, <br/>
                <b>Create an account or Sign In.</b>
            </h4>
            <FormButton path={REGISTRATION_ROUTE} name={'Create an Account'} color={'red'}/>
            <FormSplitter/>
            <FormButton path={LOGIN_ROUTE} name={'Sign In'} color={'green'}/>

            <div className="block-info">
                <strong>Have questions?</strong>
                <a href="#"
                   data-bs-toggle="modal"
                   data-bs-target={"#modalFAQ"}
                >View FAQ</a>
                <a href="#"
                   data-bs-toggle="modal"
                   data-bs-target={"#modalSupport"}
                >Contact Support</a>
            </div>
            <ModalDialogBoxContent
                title={'Frequently Asked Questions'}
                modalId={'modalFAQ'}
            >
                <h5>What is a QR code?</h5>
                <p>A QR code is similar to a barcode. When you scan the ornament's QR code, it takes you to see videos from Santa!</p>

                <h5>How do I scan a QR code?</h5>
                <p>Simply open the phone or tablet camera and point it at the QR code.</p>

                <h5>Is the QR code secure?</h5>
                <p>Yes, we've partnered with security elves that make sure your data is secure and no personal identifiable information is collected.</p>

                <h5>Am I able to use the QR code if I don't have a way to scan it?</h5>
                <p>No, you must scan the QR code to see Santa's videos. <br/>
                What if my phone doesn't have a QR code scanner? <br/>
                Most phone app stores have a third-party QR code scanner available for download.</p>

                <h5>When does Santa's Kindness Ornament start for the holiday season?</h5>
                <p>Dec 1st. Daily messages are available until Christmas Day.</p>

                <h5>Is the QR code active all year?</h5>
                <p>Yes, the QR code is active all year. Messages will be available throughout the year to encourage continued kindness!</p>

                <h5>Are batteries required?</h5>
                <p>No, batteries are not required.</p>

                <h5>Do I have to download a Santa's Kindness app to see his messages?</h5>
                <p>No, download is not required. Just scan the QR code with your phone or tablet and let the fun begin!</p>
            </ModalDialogBoxContent>
            <ModalDialogBoxContent
                title={'Support'}
                modalId={'modalSupport'}
            >
                <p className={'modal-support'}>
                    For questions, information or to share your stories of kindness, please email <br/>
                    &nbsp;<a href="mailto:Hello@SantaKindness.com"><b>Hello@SantaKindness.com</b>
                    </a> or call <a href="tel:8883363226"><b>888-336-3226</b></a>.
                </p>
            </ModalDialogBoxContent>

        </LayoutUserPage>
    );
};

export default Index;
