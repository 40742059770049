import parser from "ua-parser-js";

export const getPlatform = (userAgent) => {
    let uap = parser(userAgent);
    let osVersion = uap.os.version;
    if (osVersion == null) {
        osVersion = "";
    }
    let browserVersion = uap.browser.major;
    if (browserVersion == null) {
        browserVersion = "";
    }
    let deviceVersion = uap.device.model;
    if (deviceVersion == null) {
        deviceVersion = "";
    }

    // console.log(uap.ua.device)
    // console.log(uap.ua.os)
    // console.log(uap)
    // console.log(uap.engine.name)

    let platform = deviceVersion + " " + uap.os.name + osVersion + " " + uap.browser.name  + " " +  browserVersion;
    // platform = platform.replace(/\s/g, '');
    // console.log(platform);
    return platform;
}
