import React, {useContext, useRef, useState} from 'react';
import $ from 'jquery'
import {Context} from "../../index";
import {Link} from "react-router-dom";

const MenuItem = ({name, link, icon}) => {
    const {adminStore} = useContext(Context)

    const collapse = () => {
        if (adminStore.isSidebarCollapsed){
            adminStore.setSidebarCollapsed(false)
        }
    }

    return (
        <div className="menu-item has-sub active expand" onClick={collapse}>
            <Link to={link} className="menu-link"  title={name}>
                <div className="menu-icon"><i className={`fa ${icon}`}> </i></div>
                <div className="menu-text"> {name}</div>
            </Link>
        </div>
    );
};

export default MenuItem;
