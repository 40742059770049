import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Navigate, Outlet} from "react-router-dom";
import {ADMIN_LOGIN_ROUTE} from "../../utils/consts";
import {Context} from "../../index";
import Spinner from "../Spinner";
import LayoutAdmin from "./LayoutAdmin";

const PrivateRoute = () => {
    const {adminStore} = useContext(Context)
    console.log('PrivateRoute spin', adminStore.isAuth, !adminStore.isLoading);

    if (adminStore.isLoading) {
        return <Spinner/>
    }else{
        return adminStore.isAuth ?
            <LayoutAdmin>
                <Outlet />
            </LayoutAdmin>
            :
            <Navigate to={ADMIN_LOGIN_ROUTE} />;
    }
};

export default observer(PrivateRoute);
