import {makeAutoObservable} from "mobx";
import axios from "axios";
import {API_URL} from "../http";
import AuthUserService from "../services/AuthUserService";

export default class UserStore {
    user = {}
    isAuth = false
    isLoading = false
    isSidebarCollapsed = false
    constructor() {
        makeAutoObservable(this)
    }

    setAuth(bool){
        this.isAuth = bool
    }
    setSidebarCollapsed(bool){
        this.isSidebarCollapsed = bool
    }

    setUser(user){
        this.user = user
    }

    setLoading(boll){
        this.isLoading = boll
    }

    async login(email, password){
        console.log('email, password', email, password)
        try {
            const response = await AuthUserService.login(email, password)
            console.log('login==',response);
            localStorage.setItem('tokenUser', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
        } catch (e) {
            console.log(e.response?.data?.message)
            return { error: true, message: e.response?.data?.message}
        }
    }

    async registration(email, password, marketEmail){
        try {
            console.log('reg',email, password, marketEmail);
            const response = await AuthUserService.registration(email, password, marketEmail)
            console.log('reg', response);
            localStorage.setItem('tokenUser', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
        } catch (e) {
            console.log(e.response?.data?.message)
            return { error: true, message: e.response?.data?.message}
        }
    }

    async logout(){
        try {
            await AuthUserService.logout()
            localStorage.removeItem('tokenUser')
            this.setAuth(false)
            this.setAdmin({})
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async checkAuth(){
        this.setLoading(true)
        try {
            const response = await axios.get(`${API_URL}api/user/refresh`, {withCredentials: true})
            console.log('checkAuth', response);
            localStorage.setItem('tokenUser', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
        } catch (e) {
            console.log(e.response?.data?.message)
        } finally {
            console.log('setLoading');
            this.setLoading(false)
        }
    }
}
