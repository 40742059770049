import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import AdminStore from "./store/AdminStore";
import UserStore from "./store/UserStore";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
const adminStore =  new AdminStore()
const userStore =  new UserStore()
export const Context = createContext(null)

root.render(
    <Context.Provider value={{
        adminStore,
        userStore,
    }}>
        {/*<React.StrictMode>*/}
            <App/>
        {/*</React.StrictMode>*/}
    </Context.Provider>
);

