import React from 'react';
import {Link} from "react-router-dom";
import '../../assets/scss/components/form-button.scss'

const FormButton = ({path, name, color}) => {
    return (
        <Link to={path} className={`btn btn-${color}`}>{name}</Link>
    );
};

export default FormButton;
