import React from 'react';
import 'bootstrap'
import '../../assets/scss/components/modal-dialog-box-content.scss'

const ModalDialogBoxContent = ({title, modalId, children}) => {

    return (
        <div className="modal modal-custom fade" id={modalId} aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-body">
                        <h2 className="modal-title">{title}</h2>
                        <div className="modal-content">{children}</div>
                    </div>
                    <div className="modal-footer modal-footer-custom">
                        <a href="" className="close" data-bs-dismiss="modal">Close</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDialogBoxContent;
