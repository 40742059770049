import React from 'react';
import {observer} from "mobx-react-lite";
import imgLabel from '../assets/images/snow.svg'
import imglogo from '../assets/images/logo-red.png'
import '../assets/scss/pages/registration-form.scss'
import '../assets/scss/pages/user-verify.scss'
import '../assets/scss/pages/account-created.scss'
import FormButton from "../components/user/FormButton";
import {INDEX_ROUTE} from "../utils/consts";


const UserAccountCreated = () => {
    return (
        <div className="account-created">
            <div className="block-logo">
                <img src={imglogo} alt="logo"/>
            </div>
            <div className="registration-form user-verify">
                <h2 className={'title-label'}>
                    Your account has been created!
                    <img src={imgLabel} alt="snow"/>
                </h2>
                <span>
                If you have not been automatically redirected to the video content, <a href={'#'}>click the button below</a>.
            </span><br/>
                <span>To view videos on another device, scan the ornament QR code and sign in.</span>
                <div className={'block-button'}>
                    <FormButton path={INDEX_ROUTE} color={'red'} name={'Go to video'}/>
                </div>
            </div>
        </div>
    );
};

export default observer(UserAccountCreated);
