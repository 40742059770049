import React, {useContext} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {Context} from "../index";
import '../assets/css/default/app.min.css'
import '../assets/css/vendor.min.css'
import '../assets/css/default/fonts/bootstrap-icons.woff'
import '../assets/css/default/fonts/bootstrap-icons.woff2'
import LoginForm from "./admin/LoginForm";
import {
    ADMIN_ACCOUNT_ROUTE,
    ADMIN_FORGOT_PASSWORD_ROUTE,
    ADMIN_LOGIN_ROUTE,
    ADMIN_REGISTRATION_ROUTE,
    ADMIN_RESET_PASSWORD_ROUTE,
    ADMIN_ROUTE,
    ADMIN_TABLE_PINS_ROUTE,
    ADMIN_TABLE_ROUTE
} from "../utils/consts";
import Admin from "../pages/Admin";
import {observer} from "mobx-react-lite";
import Table from "../pages/Table";
import RegistrationForm from "./admin/RegistrationForm";
import PrivateRoute from "./admin/PrivateRoute";
import AdminProfile from "../pages/AdminProfile";
import TablePins from "../pages/TablePins";
import ResetPassword from "../pages/ResetPassword";
import ForgotPassword from "../pages/ForgotPassword";

const AdminRouter = () => {
    const {adminStore} = useContext(Context)
    console.log('AdminRouter', adminStore.isAuth, adminStore.isLoading);
    if (localStorage.getItem('token')) {
        adminStore.checkAuth()
    }
    return (
        //switch
        <Routes>
            <Route path={ADMIN_ROUTE} element={<PrivateRoute/>}>
                <Route path={ADMIN_ROUTE} element={<Admin/>}/>
                <Route path={ADMIN_ACCOUNT_ROUTE} element={<AdminProfile/>}/>
                <Route path={ADMIN_TABLE_ROUTE} element={<Table/>}/>
                <Route path={ADMIN_TABLE_PINS_ROUTE} element={<TablePins/>}/>
                <Route path={ADMIN_REGISTRATION_ROUTE} element={<RegistrationForm/>}/>
            </Route>
            <Route path={ADMIN_LOGIN_ROUTE} element={<LoginForm/>}/>
            <Route path={ADMIN_RESET_PASSWORD_ROUTE} element={<ResetPassword/>}/>
            <Route path={ADMIN_FORGOT_PASSWORD_ROUTE} element={<ForgotPassword/>}/>
            <Route path="admin/*" element={<Navigate to={ADMIN_LOGIN_ROUTE} replace/>}/>
        </Routes>

    );
};

export default AdminRouter;
