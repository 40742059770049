import React from 'react';
import MenuItem from "./MenuItem";
import ButtonCollapse from "./ButtonCollapse";
import {ADMIN_TABLE_PINS_ROUTE, ADMIN_TABLE_ROUTE} from "../../utils/consts";

const Sidebar = () => {

    return (
        <>
            <div id="sidebar" className="app-sidebar">
                <div className="app-sidebar-content" data-scrollbar="true" data-height="100%">
                    <div className="menu">
                        <div className="menu-header"> Navigation</div>

                        {/*<MenuItem nameTab='Dashboard' nameItem={nameItemDashboard} icon={'fa-sitemap'}/>*/}
                        <MenuItem name='Admins' link={ADMIN_TABLE_ROUTE} icon={'fa-th-large'}/>
                        <MenuItem name='Users' link={ADMIN_TABLE_PINS_ROUTE} icon={'fa-sitemap'}/>

                        <ButtonCollapse/>

                    </div>
                </div>
            </div>
            <div className="app-sidebar-bg"></div>
            <div className="app-sidebar-mobile-backdrop">
                <a href="#" data-dismiss="app-sidebar-mobile" className="stretched-link"> </a>
            </div>
        </>

    );
};

export default Sidebar;
