import React from 'react';
import 'bootstrap'

const ModalDialogBox = ({title, messageTitle, messageText, buttonName, buttonEvent, modalId, status}) => {

    return (
        <div className="modal fade" id={modalId} aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header"><h4 className="modal-title">{title}</h4>
                        <button type="button" className="btn-close"
                                data-bs-dismiss="modal" aria-hidden="true"></button>
                    </div>
                    <div className="modal-body">
                        <div className={`alert ${status === 'danger'?'alert-danger':''}`}>
                            <h5>
                                <i className="fa fa-info-circle"></i>
                                {messageTitle}
                            </h5>
                            {messageText &&
                                <p>{messageText}</p>
                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="" className="btn btn-white" data-bs-dismiss="modal">Close</a>
                        <a href=""
                           className={`btn ${status === 'danger'?'btn-danger':'btn-success'}`}
                           data-bs-dismiss="modal"
                           onClick={buttonEvent}
                        >
                            {buttonName}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDialogBox;
