import React from 'react';
import 'bootstrap'
import {getPlatform} from "../../utils/getPlatform";

const ModalDialogBoxContent = ({title, messageTitle, messageText, buttonName, pin, devices, buttonEvent, modalId, status}) => {
    //add map devices

    return (
        <div className="modal fade" id={modalId} aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{title}</h4>
                        <button type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-hidden="true">
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className={`alert ${status === 'danger'?'alert-danger':''}`}>
                            <h5>
                                {messageTitle}
                            </h5>
                            <h4>PIN Code {pin}:</h4>
                            <ul className='fs-5'>
                                {devices && devices.map(device =>
                                    <li>{device.name?device.name : getPlatform(device.title)}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="" className="btn btn-white" data-bs-dismiss="modal">Close</a>
                        {/*<a href=""*/}
                        {/*   className={`btn ${status === 'danger'?'btn-danger':'btn-success'}`}*/}
                        {/*   data-bs-dismiss="modal"*/}
                        {/*   onClick={buttonEvent}*/}
                        {/*>*/}
                        {/*    {buttonName}*/}
                        {/*</a>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDialogBoxContent;
