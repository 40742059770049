import React, {useRef, useState} from 'react';
import '../../assets/scss/components/input-pin.scss'

const InputPin = ({number}) => {
    const [pin, setPin] = useState('')
    const [name, setName] = useState('')
    const pinRef = useRef(null)
    const pinInput = pinRef.current
    const nameRef = useRef(null)
    const [error, setError] = useState('')

    const validatePin = (value) => {

        if (!value) {
            setError('Required')
            pinInput.classList.add('is-invalid')
        } else if (!/^[A-Z0-9]{4}\-[A-Z0-9]{3}$/i.test(value)) {
            setError('Invalid pin format (XXXX-XXX)')
            pinInput.classList.add('is-invalid')
        } else {
            setError('valid')
            pinInput.classList.remove('is-invalid')
        }
    }
    return (
        <div className="input-ornament">
            <h5>Enter your ornament’s Safe Scan PIN</h5>
            <p>
                The Safe Scan PIN is a 6 digit alphanumeric code found on the back of your ornament.
            </p>
            <div className="input-text">
                <input
                    ref={pinRef}
                    onBlur={e => validatePin(e.target.value)}
                    onChange={e => setPin(e.target.value)}
                    value={pin}
                    name={`pin_${number}`}
                    type="text"
                    placeholder={'XXXX-XXX'}
                    id={'pin'}
                    required={true}
                />
                <div className="invalid-feedback">{error}</div>
                <label
                    htmlFor="pin"
                    className=""
                >
                    PIN Code
                </label>
            </div>
            <h5>Create a name for your ornament</h5>
            <p>
                Enter your child’s name. To enter multiple names, separate each with a comma.
            </p>
            <div className="input-text">
                <input
                    ref={nameRef}
                    onChange={e => setName(e.target.value)}
                    value={name}
                    name={`name_${number}`}
                    type="text"
                    placeholder={'name'}
                    id={'name'}
                />
                <label
                    htmlFor="name"
                    className=""
                >
                    Ornament Name
                </label>
            </div>
        </div>
    );
};

export default InputPin;
