import React, {useState} from 'react';
import ModalDialogBox from "./ModalDialogBox";
import AuthService from "../../services/AuthService";
import ModalDialogBoxContent from "./ModalDialogBoxContent";

const TableRow = ({id, email, phone, marketing_email, count_devices, name, pin, status, devices}) => {
    const [isActive, setActive] = useState(status)
    const toggleActive = async () => {
        setActive(!isActive)
        await AuthService.updatePinStatus(id, !isActive)
    }

    return (
        <tr>
            <td>{id}</td>
            <td>{email}</td>
            {/*<td>{marketing_email ? 'true' : 'false'}</td>*/}
            <td>
                {count_devices ?
                    <a href="#"
                       data-bs-toggle="modal"
                       data-bs-target={"#modalContent_" + id}
                    >
                        view({count_devices})
                    </a>
                    : ''
                }

            </td>
            <td>{name}</td>
            <td>{pin}</td>
            <td>{isActive ? 'true' : 'false'}</td>
            <td>
                {isActive ?
                    <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target={"#modalAlert_" + id}
                        className="btn d-flex btn-success me-1 mb-1"
                    >Active
                    </button>
                    :
                    <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target={"#modalAlert_" + id}
                        className="btn d-flex btn-danger me-1 mb-1"
                    >Inactive
                    </button>
                }
                <ModalDialogBox
                    title={'Warning'}
                    messageTitle={'Are you sure you want to change the status?'}
                    buttonName={'Submit'}
                    buttonEvent={toggleActive}
                    modalId={'modalAlert_' + id}
                />
                <ModalDialogBoxContent
                    title={'Devices'}
                    messageTitle={''}
                    buttonName={'Submit'}
                    pin={pin}
                    devices={devices}
                    // buttonEvent={toggleActive}
                    modalId={'modalContent_' + id}
                />
            </td>
        </tr>
    );
};

export default TableRow;
