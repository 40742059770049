import React, {useContext, useEffect, useRef, useState} from 'react';
import {Context} from "../../index";
import {Link, useNavigate} from "react-router-dom";
import {ADMIN_FORGOT_PASSWORD_ROUTE, ADMIN_ROUTE, ADMIN_TABLE_PINS_ROUTE} from "../../utils/consts";
import {observer} from "mobx-react-lite";

const LoginForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const inputRef = useRef()
    const {adminStore} = useContext(Context)
    const history = useNavigate()

    console.log('LoginForm', adminStore.isAuth, ADMIN_ROUTE);
    useEffect(() => {
        if (adminStore.isAuth) {
            history(ADMIN_TABLE_PINS_ROUTE)
        }
    }, [])

    const signIn = async () => {

        try {
            if (email && password){
                await adminStore.login(email, password).then((response)=>{
                    console.log('login true = ',response);
                    if(response?.error){
                        inputRef.current.classList.add('is-invalid')
                        setError(response.message)
                    }else{
                        inputRef.current.classList.remove('is-valid')
                        history(ADMIN_TABLE_PINS_ROUTE)
                    }
                })
                inputRef.current.classList.remove('is-valid')
            }else{
                inputRef.current.classList.add('is-invalid')
                setError('Fields must not be left blank')
            }

        } catch (e) {
            console.log('login false = ',e.response?.data);
        }

    }
    return (
        <div className="login login-v1">
            <div className="login-container">
                <div className="login-header">
                    <div className="brand">
                        <div className="d-flex align-items-center">
                            <span className="logo"></span> <b>Dashboard</b> Admin
                        </div>
                    </div>
                    <div className="icon">
                        <i className="fa fa-lock"></i>
                    </div>
                </div>
                <div className="login-body">
                    <div className="login-content fs-13px">
                        <form action="" method="">
                            <div className="form-floating mb-20px">
                                <input
                                    ref={inputRef}
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    type="email"
                                    className="form-control fs-13px h-45px"
                                    id="emailAddress"
                                    placeholder="Email Address"
                                    required
                                />
                                <div className="invalid-tooltip">{error}</div>
                                <label
                                    htmlFor="emailAddress"
                                    className="d-flex align-items-center py-0"
                                >
                                    Email Address
                                </label>
                            </div>
                            <div className="form-floating mb-20px">
                                <input
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    type="password"
                                    className="form-control fs-13px h-45px"
                                    id="password"
                                    placeholder="Password"
                                    required
                                />
                                <label
                                    htmlFor="password"
                                    className="d-flex align-items-center py-0"
                                >
                                    Password
                                </label>
                            </div>

                            <div className="login-buttons mb-20px">
                                <button
                                    type="button"
                                    onClick={signIn}
                                    className="btn h-45px btn-success w-100 btn-lg"
                                >
                                    Sign in
                                </button>
                            </div>

                            <div className="text-gray-500">Forgot your password? Click&nbsp;
                                <Link to={ADMIN_FORGOT_PASSWORD_ROUTE} className="text-white">
                                    here.
                                </Link>
                            </div>
                        </form>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default observer(LoginForm);
