export const ADMIN_ROUTE = '/admin'
export const ADMIN_TABLE_ROUTE = '/admin/table'
export const ADMIN_TABLE_PINS_ROUTE = '/admin/table-pins'
export const ADMIN_ACCOUNT_ROUTE = '/admin/account/:id'
export const ADMIN_REGISTRATION_ROUTE = '/admin/registration'
export const ADMIN_LOGIN_ROUTE = '/admin/login'
export const ADMIN_RESET_PASSWORD_ROUTE = '/admin/reset-password/:id/:token'
export const ADMIN_FORGOT_PASSWORD_ROUTE = '/admin/forgot-password'


export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const USER_ROUTE = '/user'
export const INDEX_ROUTE = '/'
export const USER_VERIFY_ROUTE = '/user/verify'
export const USER_ACCOUNT_CREATED_ROUTE = '/user/account-created'
export const USER_PIN_AUTHORIZATION_ROUTE = '/user/pin-authorization'
export const USER_VERIFY_MESSAGE_ROUTE = '/user/verify-message'
export const USER_ACTIVATE_PIN_ROUTE = '/user/activate-pin'
