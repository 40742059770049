import React from 'react';

const LayoutTable = ({panelTitle, children}) => {
    return (
        <div className="panel panel-inverse m-1">
            <div className="panel-heading undefined">
                <h4 className="panel-title">{panelTitle}</h4>
            </div>
            <div className="panel-body">
                <div className="dataTables_wrapper">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default LayoutTable;
