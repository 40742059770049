import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Navigate, Outlet} from "react-router-dom";
import {INDEX_ROUTE} from "../../utils/consts";
import {Context} from "../../index";
import Spinner from "../Spinner";

const PrivateRoute = () => {
    const {userStore} = useContext(Context)
    console.log('PrivateRoute spin', userStore.isAuth, !userStore.isLoading);

    if (userStore.isLoading) {
        return <Spinner/>
    } else {
        return userStore.isAuth ?
            <Outlet/>
            :
            <Navigate to={INDEX_ROUTE}/>;
    }
};

export default observer(PrivateRoute);
