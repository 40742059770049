import React, {useContext, useState} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import '../assets/scss/pages/registration-form.scss'
import '../assets/scss/pages/pin-form.scss'
import imgLabel from '../assets/images/snow.svg'
import ModalDialogBoxContent from "../components/user/ModalDialogBoxContent";
import InputPin from "../components/user/InputPin";
import $ from 'jquery'
import AuthUserService from "../services/AuthUserService";
import {useNavigate} from "react-router-dom";
import {USER_ACCOUNT_CREATED_ROUTE} from "../utils/consts";

const UserActivatePin = () => {
    const {userStore} = useContext(Context)
    const history = useNavigate()
    const [errors, setErrors] = useState([])
    const user = userStore.user
    const [numChildren, setNumChildren] = useState(0)
    const children = []

    for (let i = 0; i < numChildren; i++) {
        children.push(<InputPin key={i} number={i + 1}/>)
    }
    const addComponent = (e) => {
        e.preventDefault()
        setNumChildren((count) => count + 1)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        console.log($(form).find('input.is-invalid').length);

        if ($(form).find('input.is-invalid').length === 0) {
            const formJson = Object.fromEntries(formData.entries());
            let arr = []
            let iter = 0
            const pinArr = []

            for (const [key, value] of Object.entries(formJson)) {
                arr.push(value)
            }
            let pinStr = ''
            let nameStr = ''
            arr.forEach((value, index) => {
                iter++
                if (iter <= 2) {
                    if (iter === 1) {
                        pinStr = value.toUpperCase()
                    } else {
                        nameStr = value
                    }
                    if (iter === 2) {
                        pinArr.push({pin: pinStr, name: nameStr})
                        iter = 0
                    }
                }
            })
            try {
                await AuthUserService.activatePinStatus(user.id, user.email, pinArr, navigator.userAgent).then((response) => {
                    // console.log('asdasdasd====----',response);
                    if (response?.error) {
                        setErrors(response.error)
                    } else {
                        setErrors([])
                        history(USER_ACCOUNT_CREATED_ROUTE)
                    }
                })
            } catch (e) {
                console.log(e.response)
                setErrors(e.response?.data?.errors)
                return {error: true, message: e.response?.data?.message}
            }
        }
    }

    return (
        <div className="registration-form pin-form">
            <h2 className={'title-label'}>
                Enter Safe Scan PIN
                <img src={imgLabel} alt="snow"/>
            </h2>

            <div className={'form-info'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                        fill="#006548"/>
                </svg>
                Each ornament is limited to 5 devices.
            </div>
            <div className="error-info">
                {errors && errors.map(message =>
                    message?.error && <>{message?.error}<br/></>
                )}
            </div>

            <form action="" method="" onSubmit={handleSubmit}>

                <InputPin number={0}/>
                {children}

                <div className="block-add-component">
                    <span>Have multiple ornaments?</span>
                    <a href="#" onClick={addComponent}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="#006548"/>
                        </svg>
                        Add another ornament
                    </a>
                </div>


                <div className="block-info">
                    <strong>Can’t find your PIN?</strong>
                    <a href="#"
                       data-bs-toggle="modal"
                       data-bs-target={"#modalFAQ"}
                    >View FAQ</a>
                    <a href="#"
                       data-bs-toggle="modal"
                       data-bs-target={"#modalSupport"}
                    >Contact Support</a>
                </div>

                <button type="submit" className={'btn btn-red'}>Next</button>
            </form>
            <ModalDialogBoxContent
                title={'Frequently Asked Questions'}
                modalId={'modalFAQ'}
            >
                <h5>What is a QR code?</h5>
                <p>A QR code is similar to a barcode. When you scan the ornament's QR code, it takes you to see videos
                    from Santa!</p>

                <h5>How do I scan a QR code?</h5>
                <p>Simply open the phone or tablet camera and point it at the QR code.</p>

                <h5>Is the QR code secure?</h5>
                <p>Yes, we've partnered with security elves that make sure your data is secure and no personal
                    identifiable information is collected.</p>

                <h5>Am I able to use the QR code if I don't have a way to scan it?</h5>
                <p>No, you must scan the QR code to see Santa's videos. <br/>
                    What if my phone doesn't have a QR code scanner? <br/>
                    Most phone app stores have a third-party QR code scanner available for download.</p>

                <h5>When does Santa's Kindness Ornament start for the holiday season?</h5>
                <p>Dec 1st. Daily messages are available until Christmas Day.</p>

                <h5>Is the QR code active all year?</h5>
                <p>Yes, the QR code is active all year. Messages will be available throughout the year to encourage
                    continued kindness!</p>

                <h5>Are batteries required?</h5>
                <p>No, batteries are not required.</p>

                <h5>Do I have to download a Santa's Kindness app to see his messages?</h5>
                <p>No, download is not required. Just scan the QR code with your phone or tablet and let the fun
                    begin!</p>
            </ModalDialogBoxContent>
            <ModalDialogBoxContent
                title={'Support'}
                modalId={'modalSupport'}
            >
                <p className={'modal-support'}>
                    For questions, information or to share your stories of kindness, please email <br/>
                    &nbsp;<a href="mailto:Hello@SantaKindness.com"><b>Hello@SantaKindness.com</b>
                </a> or call <a href="tel:8883363226"><b>888-336-3226</b></a>.
                </p>
            </ModalDialogBoxContent>
        </div>
    );
};

export default observer(UserActivatePin);

