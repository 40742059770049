import $api from "../http";

export default class AuthUserService {
    static async login(email, password) {
        return $api.post('api/user/login', {email, password},{
            withCredentials: true,
            // credentials: "same-origin",
            credentials: 'include',
            // headers: {'Content-Type': 'application/json' }
        })
    }

    static async registration(email, password, marketEmail) {
        // console.log('registration===',email, phone, password)
        return $api.post('api/user/registration', {email, password, marketEmail})
    }

    static async logout() {
        return $api.post('api/admin/logout')
    }

    static update(id, password, firstName, lastName){
        // console.log('ser',id, password, firstName, lastName)
        return $api.put('api/admin/update', {id, password, firstName, lastName})
    }

    static activatePinStatus(userId, email, pin, device){
        console.log('updatePinStatus',userId, email, pin)
        return $api.put('api/user/activate-pin', {userId, email, pin, device})
    }
    static bindDeviceToPin(pinId, device){
        console.log('updatePinStatus',pinId, device)
        return $api.post('api/user/device-pin', {pinId, device})
    }

    static updatePinStatus(id, status){
        console.log('updatePinStatus',id, status)
        return $api.put('api/admin/update-pin-status', {id, status})
    }

    static delete(id){
        return $api.delete('api/admin/delete/'+id)
    }
    static sendEmailResetPassword(email){
        return $api.post('api/admin/password-reset',{email})
    }

    static resetPassword(id, token, password){
        return $api.post(`api/admin/password-reset/${id}/${token}`,{password})
    }
}
