import $api from "../http";

export default class AuthService {
    static async login(email, password) {
        return $api.post('api/admin/login', {email, password})
    }

    static async registration(email, firstName, lastName) {
        // console.log('registration===',email, firstName, lastName)
        return $api.post('api/admin/registration', {email, firstName, lastName})
    }

    static async logout() {
        return $api.post('api/admin/logout')
    }

    static update(id, password, firstName, lastName){
        // console.log('ser',id, password, firstName, lastName)
        return $api.put('api/admin/update', {id, password, firstName, lastName})
    }

    static updatePinStatus(id, status){
        console.log('updatePinStatus',id, status)
        return $api.put('api/admin/update-pin-status', {id, status})
    }

    static delete(id){
        return $api.delete('api/admin/delete/'+id)
    }
    static sendEmailResetPassword(email){
        return $api.post('api/admin/password-reset',{email})
    }

    static resetPassword(id, token, password){
        return $api.post(`api/admin/password-reset/${id}/${token}`,{password})
    }
}
