import axios from "axios";
import baseUrl from '../config.json'

export const API_URL = process.env.REACT_APP_ENV === 'stage' ? process.env.REACT_APP_API_URL : "http://68.183.119.174:5000/"
const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL
})
console.log(baseUrl.REACT_APP_API_URL);

$api.interceptors.request.use((config) => {
    config.headers.tokenAdmin = `Bearer ${localStorage.getItem('token')}`
    config.headers.tokenUser = `Bearer ${localStorage.getItem('tokenUser')}`
    console.log('asdasd',config.headers.tokenUser )
    return config
})

$api.interceptors.response.use((config) => {
    console.log('asdasd',config )
    return config;
},async (error) => {
    const originalRequest = error.config;
    console.log('asdasd',error )

    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            console.log(error, error.response.status === 401 && error.config && !error.config._isRetry);
            if (originalRequest.response?.data?.errors[0] === 'user_error'){
                const response = await axios.get(`${API_URL}api/user/refresh`, {withCredentials: true})
                localStorage.setItem('tokenUser', response.data.accessToken);
            }else if (originalRequest.response?.data?.errors[0] === 'admin_error'){
                const response = await axios.get(`${API_URL}api/admin/refresh`, {withCredentials: true})
                localStorage.setItem('token', response.data.accessToken);
            }

            return $api.request(originalRequest);
        } catch (e) {
            console.log('Not authorized', e);
            if (originalRequest.response?.data?.errors[0] === 'user_error'){
                return window.location.href = window.location.origin + '/login'
            }else if(originalRequest.response?.data?.errors[0] === 'admin_error'){
                return window.location.href = window.location.origin + '/admin/login'
            }
        }
    }
    throw error;
})


export default $api
// {
// $host,
// $authHost
// }
