import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import '../assets/scss/pages/registration-form.scss'
import imgLabel from '../assets/images/snow.svg'
import '../assets/scss/pages/user-verify.scss'
import {Context} from "../index";


const UserVerifyMessage = () => {

    const {userStore} = useContext(Context)
    console.log('UserVerify', userStore.user.email);

    return (
        <div className="registration-form user-verify">
            <h2 className={'title-label'}>
                Verify Email Address
                <img src={imgLabel} alt="snow"/>
            </h2>
            <span>
                We’ve sent a verification email to <a href={`mailto:${userStore.user.email}`}>{userStore.user.email}</a>.
            </span><br/>
            <span>Click on the link in the email to continue signing up.</span>
            <div className={'block-button'}>
                <button type="button" className={'btn btn-grey'} disabled={true}>Next</button>
            </div>

        </div>
    );
};

export default observer(UserVerifyMessage);
