import React from 'react';
import HeaderNav from "./HeaderNav";

const NavbarHeader = () => {

    return (
        <div id="header" className="app-header">
            <div className="navbar-header">
                <a href="/" className="navbar-brand"><span className="navbar-logo"></span>
                    <b>Dashboard&#8195;</b>Admin</a>
                <button type="button" className="navbar-mobile-toggler" data-toggle="app-sidebar-mobile">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>

            </div>
            <HeaderNav/>
        </div>
    );
};

export default NavbarHeader;
