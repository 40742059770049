import React, {useContext} from 'react';
import {Context} from "../../index";
import NavbarHeader from "./NavbarHeader";
import Sidebar from "./Sidebar";
import {observer} from "mobx-react-lite";

const LayoutAdmin = ({children}) => {
    const {adminStore} = useContext(Context)
    return (
        <div className={adminStore.isSidebarCollapsed ? "app-sidebar-minified" : ""}>
            <NavbarHeader/>
            <Sidebar/>
            <div id="content" className="app-content">
                {children}
            </div>
        </div>
    );
};

export default observer(LayoutAdmin);
