import React, {useContext} from "react";
import {BrowserRouter} from "react-router-dom";
import {Context} from "./index";
import AdminRouter from "./components/AdminRouter";
import UserRouter from "./components/UserRouter";

const App = function () {
    return (
        <div>
            <BrowserRouter>
                <AdminRouter/>
                <UserRouter/>
            </BrowserRouter>
        </div>

    );
}


export default App;
